// require( './src/styles/normalize.css' );
require( './src/styles/app.css' );

exports.onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if ( !( `IntersectionObserver` in window ) ) {
    await require( `intersection-observer` );
    console.log( `# IntersectionObserver is polyfilled!` );
  }
};
